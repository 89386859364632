import React from "react";
import classNames from "classnames";

const FooterSocial = ({ className, ...props }) => {
  const classes = classNames("footer-social", className);

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <a href="https://reddit.com/r/AtomForReddit" target="_blank">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="icon">
              <path d="M13.687 12.845a0.845 0.845 0 1 0 1.687 0 0.845 0.845 0 1 0 -1.687 0zm3.376 -2.532a0.841 0.841 0 0 0 -0.762 0.484c0.44 0.335 0.805 0.72 1.075 1.144A0.844 0.844 0 0 0 17.063 10.313zM12 1.5C6.202 1.5 1.5 6.202 1.5 12s4.7 10.5 10.5 10.5 10.5 -4.7 10.5 -10.5S17.798 1.5 12 1.5zm5.742 11.201c0.108 0.317 0.163 0.647 0.163 0.988 0 2.329 -2.644 4.219 -5.908 4.219s-5.908 -1.889 -5.908 -4.219c0 -0.341 0.055 -0.671 0.163 -0.988A1.687 1.687 0 0 1 6.937 9.469c0.635 0 1.187 0.349 1.475 0.866 0.848 -0.463 1.879 -0.768 3.002 -0.846l1.368 -3.072c0.101 -0.229 0.355 -0.347 0.598 -0.276l2.147 0.62a1.266 1.266 0 0 1 2.381 0.6c0 0.7 -0.568 1.266 -1.266 1.266 -0.551 0 -1.02 -0.354 -1.193 -0.846L13.524 7.225l-1.008 2.262c1.151 0.071 2.208 0.378 3.076 0.851 0.288 -0.517 0.839 -0.866 1.475 -0.866 0.932 0 1.687 0.755 1.687 1.687 -0.004 0.688 -0.418 1.279 -1.009 1.542zm-4.015 1.945c-0.349 0.275 -1.038 0.57 -1.728 0.57s-1.38 -0.295 -1.728 -0.57c-0.217 -0.172 -0.533 -0.133 -0.703 0.084 -0.172 0.217 -0.133 0.533 0.084 0.703 0.604 0.479 1.524 0.785 2.346 0.785 0.822 0 1.745 -0.307 2.35 -0.785 0.217 -0.172 0.256 -0.487 0.084 -0.703a0.503 0.503 0 0 0 -0.704 -0.084zM6.937 10.313a0.844 0.844 0 0 0 -0.313 1.626c0.27 -0.425 0.635 -0.809 1.075 -1.144A0.841 0.841 0 0 0 6.937 10.313zm1.687 2.532a0.845 0.845 0 1 0 1.687 0 0.845 0.845 0 1 0 -1.687 0z" />
            </svg>
          </a>
        </li>

        <li>
          <a href="https://x.com/AtomForReddit" target="_blank">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <title>Twitter</title>
              <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
              </svg>
          </a>
        </li>
        <li>
          <a href="https://instagram.com/AtomForReddit" target="_blank">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <title>Instagram</title>
              <g>
                <circle cx="12.145" cy="3.892" r="1" />
                <path d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
                <path d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z" />
              </g>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
